import React from 'react';
import { TrainingCalendar } from './TrainingCalendar';








class App extends React.Component {
  
   render() {
     return (
       <TrainingCalendar/>
     )
   }
  
}

export default App;
