import DateFnsUtils from '@date-io/date-fns';
import { Button, ButtonGroup, Grid, MenuItem, Select } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { add, isSameDay, startOfDay } from 'date-fns';
import memoizeOne from 'memoize-one';
import React, { Fragment } from 'react';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import { DATE, getEvents, locales, getResources, localizer, messages, withData, eventProp, minMax } from './CalendarHelpers';
import KeyHandler from 'react-key-handler';
import { Calendar } from 'react-big-calendar'
import { clubs, locations } from './Events';
import './App.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'

const mapLocations = (locations) => {
    let map = {}
    for (const loc of locations) {
        map[loc.id] = loc
    }
    return map;
}

const EventAgenda = ({ event, locations }) => {

    return (
        <span>
            <span>{event.title}</span>
            <sup style={{marginLeft: 8, color: 'magenta', fontSize: '0.6em'}}><em>{locations[event.location].title}</em></sup>
            <p>{event.desc}</p>
        </span>
    )
}


class Toolbar extends React.Component {
    render() {
        let {
            localizer: { messages },
            date,
            onNavigate,
            selector,
            isClub
        } = this.props

        const sameDay = isSameDay(DATE, date) || null

        return <Grid container style={{ marginBottom: 8 }} justify='space-between'>
            <div style={{ display: 'inline-flex' }}>
                <div style={{ marginRight: 8 }}>{selector}</div>
                {isClub && <ButtonGroup>
                    <Button onClick={this.navigate(navigate.PREVIOUS)}>{messages.previous}</Button>
                    <Button color={sameDay && 'primary'} variant={sameDay && 'contained'} onClick={this.navigate(navigate.TODAY)}>{messages.today}</Button>
                    <Button onClick={this.navigate(navigate.NEXT)}>{messages.next}</Button>
                </ButtonGroup>}
            </div>
            {isClub && <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locales.de}>
                <DatePicker
                    onChange={(date) => console.info('fufu', date instanceof Date, date) || onNavigate(null, new Date(date.valueOf()))}
                    value={date}
                    autoOk
                    format="EEEEEE, dd. MMMM"
                    inputVariant='outlined'
                />
                <ButtonGroup>
                    {this.viewNamesGroup(messages)}
                </ButtonGroup>
            </MuiPickersUtilsProvider>}
        </Grid>
    }

    navigate = action => () => {
        this.props.onNavigate(action)
    }

    view = view => () => {
        this.props.onView(view)
    }

    trueNull = (value) => Boolean(value) || null

    viewNamesGroup(messages) {
        let viewNames = this.props.views
        const view = this.props.view

        if (viewNames.length > 1) {
            return viewNames.map(name => (
                <Button
                    key={name}
                    color={this.trueNull(name === view) && 'primary'}
                    variant={this.trueNull(name === view) && 'contained'}
                    onClick={this.view(name)}
                >
                    {messages[name]}
                </Button>
            ))
        }
    }
}

const ClubSelector = ({ options, value, onChange, ...props }) => (
    console.info('data is', value || undefined) ||
    <Select
        variant='outlined'
        value={value || ''}
        displayEmpty
        onChange={onChange}
    >
        <MenuItem value='' disabled>--Verein aussuchen--</MenuItem>
        {options.map(option =>
            <MenuItem key={`club-${option.id}`} value={option.id}>{option.title}</MenuItem>
        )}
    </Select>
)




export class TrainingCalendar extends React.Component {
    state = {
        view: 'day',
        club: null,
        date: new Date()
    }

    selectClub = (e) => this.setState({ club: Number(e.target.value) })
    changeView = view => this.setState({ view })
    changeDate = date => this.setState({ date: startOfDay(date) })
    increaseDate = (days) => () => this.setState(state => ({
        date: add(state.date, { days })
    }))
    memorizedEvents = memoizeOne(getEvents)
    memorizedResources = memoizeOne(getResources)
    memorizedMinMax = memoizeOne(minMax)
    memorizedLocations = memoizeOne(mapLocations)

    render() {
        const { view, club: clubSelection, date } = this.state;
        const events = this.memorizedEvents(view, date, clubSelection)
        const resources = this.memorizedResources(clubSelection)
        const [low, high] = this.memorizedMinMax(DATE, events, 1)
        const locs = this.memorizedLocations(locations)
        return (
            <div className="App">
                {clubSelection && <Fragment>
                    <KeyHandler keyValue='ArrowLeft' onKeyHandle={this.increaseDate(-1)} />
                    <KeyHandler keyValue='ArrowRight' onKeyHandle={this.increaseDate(1)} />
                </Fragment>}
                <Calendar
                    view={view}
                    views={['day', 'agenda']}
                    events={events}
                    localizer={localizer}
                    culture='de'
                    messages={messages}
                    resources={resources}
                    resourceAccessor={'location'}
                    date={date}
                    onNavigate={this.changeDate}
                    onView={this.changeView}
                    min={low}
                    max={high}
                    components={{
                        toolbar: withData({ isClub: !!clubSelection, selector: <ClubSelector options={clubs} value={clubSelection} onChange={this.selectClub} /> })(Toolbar),
                        agenda: {
                            event: withData({ locations: locs })(EventAgenda)
                        }
                    }}
                    eventPropGetter={eventProp}
                />
            </div>
        )
    }
}