export const clubs = [
    {
        id: 1,
        title: 'Legau'
    },
    {
        id: 2,
        title: 'Kimratshofen'
    }
]

export const locations = [
    {
        id: 1,
        club: 1,
        title: 'Hautplatz'
    },
    {
        id: 2,
        club: 1,
        title: 'Trainingsplatz'
    },
    {
        id: 3,
        club: 1,
        title: 'Kleinfeld'
    },
    {
        id: 4,
        club: 2,
        title: 'Hautplatz'
    },
    {
        id: 5,
        club: 2,
        title: 'Trainingsplatz'
    },
    {
        id: 6,
        club: 2,
        title: 'Kleinfeld'
    }
]

export const weekdays = ['so', 'mo', 'di', 'mi', 'do', 'fr', 'sa']
export const weekmap = {
    'mo': 1,
    'di': 2,
    'mi': 3,
    'do': 4,
    'fr': 5,
    'sa': 6,
    'so': 0
}

export const colors = {
    training: null,
    match: '#ff0000'
}

export const staticEvents = [
    {
        id: 1,
        club: [1],
        location: 2,
        title: '1. Mannschaft',
        weekday: 'di',
        starts: '19:00',
        ends: '21:00',
        bgColor: colors.training 
    },
    {
        id: 2,
        club: [1],
        location: 2,
        title: '1. Mannschaft',
        weekday: 'fr',
        starts: '19:00',
        ends: '21:00',
        bgColor: colors.training
    },
    {
        id: 3,
        club: [1],
        location: 2,
        title: '2. Mannschaft',
        weekday: 'di',
        starts: '19:00',
        ends: '21:00',
        bgColor: colors.training
    },
    {
        id: 4,
        club: [1],
        location: 2,
        title: '2. Mannschaft',
        weekday: 'fr',
        starts: '19:00',
        ends: '21:00',
        bgColor: colors.training
    },
    {
        id: 5,
        club: [1, 2],
        location: 5,
        title: 'A-Jugend',
        weekday: 'mo',
        starts: '19:00',
        ends: '20:30',
        bgColor: colors.training
    },
    {
        id: 6,
        club: [1, 2],
        location: 2,
        title: 'A-Jugend',
        weekday: 'mi',
        starts: '19:00',
        ends: '20:30',
        bgColor: colors.training
    },
    {
        id: 7,
        club: [1, 2],
        location: 5,
        title: 'B-Jugend',
        weekday: 'mo',
        starts: '19:00',
        ends: '20:30',
        bgColor: colors.training
    },
    {
        id: 8,
        club: [1, 2],
        location: 2,
        title: 'B-Jugend',
        weekday: 'mi',
        starts: '19:00',
        ends: '20:30',
        bgColor: colors.training
    },
    {
        id: 9,
        club: [1, 2],
        location: 2,
        title: 'C-Jugend',
        weekday: 'di',
        starts: '17:30',
        ends: '19:00',
        bgColor: colors.training
    },
    {
        id: 10,
        club: [1, 2],
        location: 2,
        title: 'C-Jugend',
        weekday: 'do',
        starts: '18:00',
        ends: '19:30',
        bgColor: colors.training
    },
    {
        id: 11,
        club: [1, 2],
        location: 2,
        title: 'D-Jugend',
        weekday: 'mo',
        starts: '17:30',
        ends: '19:00',
        bgColor: colors.training
    },
    {
        id: 12,
        club: [1, 2],
        location: 5,
        title: 'D-Jugend',
        weekday: 'mi',
        starts: '17:30',
        ends: '19:00',
        bgColor: colors.training
    },
    {
        id: 13,
        club: [1],
        location: 1,
        title: 'E-Jugend',
        weekday: 'mo',
        starts: '17:30',
        ends: '18:45',
        bgColor: colors.training
    },
    {
        id: 14,
        club: [1],
        location: 2,
        title: 'E-Jugend',
        weekday: 'mi',
        starts: '17:30',
        ends: '18:45',
        bgColor: colors.training
    },
    {
        id: 15,
        club: [1],
        location: 1,
        title: 'F-Jugend',
        weekday: 'mo',
        starts: '17:30',
        ends: '18:45',
        bgColor: colors.training
    },
    {
        id: 16,
        club: [1],
        location: 2,
        title: 'F-Jugend',
        weekday: 'mi',
        starts: '17:30',
        ends: '18:45',
        bgColor: colors.training
    },
    {
        id: 17,
        club: [1],
        location: 1,
        title: 'Bambinis',
        weekday: 'do',
        starts: '17:30',
        ends: '18:45',
        bgColor: colors.training
    },
    {
        club: [1],
        location: 3,
        title: 'AH',
        weekday: 'di',
        starts: '19:15',
        ends: '21:00',
        bgColor: colors.training
    },
    {
        club: [2],
        location: 5,
        title: '1. Mannschaft',
        weekday: 'di',
        starts: '19:15',
        ends: '21:00',
        bgColor: colors.training
    },
    {
        club: [2],
        location: 5,
        title: '2. Mannschaft',
        weekday: 'di',
        starts: '19:15',
        ends: '21:00',
        bgColor: colors.training
    },
    {
        club: [2],
        location: 5,
        title: '1. Mannschaft',
        weekday: 'do',
        starts: '19:15',
        ends: '21:00',
        bgColor: colors.training
    },
    {
        club: [2],
        location: 5,
        title: '2. Mannschaft',
        weekday: 'do',
        starts: '19:15',
        ends: '21:00',
        bgColor: colors.training
    },
    {
        club: [2],
        location: 5,
        title: 'E-Jugend',
        weekday: 'mo',
        starts: '17:30',
        ends: '18:45',
        bgColor: colors.training
    },
    {
        club: [2],
        location: 5,
        title: 'E-Jugend',
        weekday: 'mi',
        starts: '17:30',
        ends: '18:45',
        bgColor: colors.training
    },
    {
        club: [2],
        location: 5,
        title: 'F-Jugend',
        weekday: 'mo',
        starts: '17:30',
        ends: '18:45',
        bgColor: colors.training
    },
    {
        club: [2],
        location: 6,
        title: 'F-Jugend',
        weekday: 'mi',
        starts: '17:30',
        ends: '18:45',
        bgColor: colors.training
    },
    {
        club: [2],
        location: 6,
        title: 'Bambinis',
        weekday: 'mo',
        starts: '17:30',
        ends: '18:30',
        bgColor: colors.training
    }
].map((v, i) => {
    v.id = i+1
    return v
})