import React from 'react'
import { locations, staticEvents, weekmap } from './Events'
import { format, parse, startOfWeek, getDay, endOfWeek, startOfMonth, endOfMonth, startOfDay, endOfDay, add, eachDayOfInterval, set, startOfMinute, max, min, isValid, sub } from 'date-fns'
import de from 'date-fns/locale/de'
import { dateFnsLocalizer } from 'react-big-calendar'
import Color from 'color'



export const locales = {
    de
}
export const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

export const messages = {
    allDay: 'Ganzen Tag',
    previous: '<',
    next: '>',
    today: 'Heute',
    month: 'Monat',
    week: 'Woche',
    day: 'Tag',
    agenda: 'Liste',
    date: 'Datum',
    time: 'Zeit',
    event: 'Ereignis',
    noEventsInRange: 'Keine Ereignisse im Rahmen',
    showMore: () => 'Zeige mehr',
}

export const DATE = startOfDay(new Date())
export const END_DATE = endOfDay(DATE)

export const timeDate = (baseDate, time) => {
    time = time.split(':').map(Number)
    return set(baseDate, { hours: time[0], minutes: time[1] || 0, seconds: time[2] || 0 })
}

export const getRanges = (view, date) => {
    switch (view) {
        case 'week':
            return [startOfWeek(date, { weekStartsOn: 1 }), endOfWeek(date, { weekStartsOn: 1 })]
        case 'month':
            return [startOfMonth(date), endOfMonth(date)]
        case 'day':
            return [startOfDay(date), endOfDay(date)]
        case 'agenda':
            return [startOfDay(date), endOfDay(add(date, { days: 30 }))]
        default:
            return [date, date]
    }
}

export const getDays = range => eachDayOfInterval({ start: range[0], end: range[1] })

export const mapEvents = (days, events) => {
    let list = [];
    for (const date of days) {
        for (const event of events) {
            if (getDay(date) === weekmap[event.weekday]) {
                const ev = { ...event };
                ev.start = startOfMinute(timeDate(date, event.starts))
                ev.end = startOfMinute(timeDate(date, event.ends))
                list.push(ev)
            }
        }
    }
    return list
}

export const getLocations = (club) => {
    let list = locations.filter(l => l.club === club)
    let map = {}
    for (const loc of list) {
        map[loc.id] = loc
    }
    return map
}

export const getClubEvents = (events, locs) => events.filter(e => e.location in locs)

export const getEvents = (view, date, club = null) => {
    if (null === club) return []
    const range = getRanges(view, date)
    const days = getDays(range)
    const locs = getLocations(club)
    let events = getClubEvents(staticEvents, locs)
    return mapEvents(days, events)
}

export const getResources = (club) => {
    if (null === club) return null
    return locations.filter(l => l.club === club)
}

const _minMax = (baseDate, accessor) => (e) => {
    const hours = e[accessor].getHours()
    const minutes = e[accessor].getMinutes()
    const date = set(baseDate, { hours, minutes })
    return startOfMinute(date)
}

export const minMax = (baseDate, events, hours = 0) => {
    let low = startOfDay(baseDate)
    let high = endOfDay(baseDate)
    low = min(events.map(_minMax(baseDate, 'start')))
    if (low && hours) {
        low = max([startOfDay(baseDate), sub(low, { hours })])
    }
    high = max(events.map(_minMax(baseDate, 'end')))
    if (high && hours) {
        high = min([endOfDay(baseDate), add(high, { hours })])
    }
    return [isValid(low) ? low : undefined, isValid(high) ? high : undefined]
}

export const withData = (data) => (Component) => (props) => <Component {...data} {...props} />

export const blackOrWhite = (clr) => Color(clr).luminosity() < 0.5 ? '#fff' : '#000'

export const eventProp = (event) => ({
    className: '',
    style: {
        backgroundColor: event.bgColor || null,
        color: event.bgColor ? blackOrWhite(event.bgColor) : null
    }
})